<template>
    <div class="container" style="background-color: #fff;">
        <h1>{{ $store.state.language.layout.bread_crumbs.inquiry }}</h1>
        <div class="row">
            <div class="col-12 mb-3">
                <table
                    class="inquiry_table"
                    style="width: 100%"
                    v-if="formData.products.length > 0"
                >
                    <thead>
                        <tr>
                            <th class="text-center">
                                {{ $store.state.language.inquiry.table.no }}
                            </th>
                            <th class="text-center">
                                {{ $store.state.language.inquiry.table.image }}
                            </th>
                            <th>
                                {{
                                    $store.state.language.inquiry.table
                                        .product_name
                                }}
                            </th>
                            <th class="text-center">
                                {{
                                    $store.state.language.inquiry.table
                                        .operating
                                }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(p, pk) in formData.products"
                            :key="`p${pk}`"
                        >
                            <td class="text-center">{{ pk + 1 }}</td>
                            <td class="text-center">
                                <img
                                    class="main"
                                    width="100"
                                    :src="p.main_img"
                                    :alt="p.name"
                                />
                            </td>
                            <td>{{ p.model }} {{ p.name }}</td>
                            <td class="text-center">
                                <a
                                    class="btn btn-danger btn-sm"
                                    @click="del_inquiry(pk)"
                                >
                                    <i class="fa fa-trash"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12">
                <label for="fr_comment">{{
                    $store.state.language.inquiry.comment
                }}</label>
                <textarea
                    class="form-control"
                    id="fr_comment"
                    v-model="formData.comment"
                ></textarea>
            </div>
            <div
                class="col-lg-6"
                :class="check_form && formData.company == '' ? 'invalid' : ''"
            >
                <label for="fr_company">
                    <span class="red">*</span>
                    {{ $store.state.language.inquiry.company }}
                </label>
                <input
                    type="text"
                    class="form-control"
                    id="fr_company"
                    v-model="formData.company"
                />
                <span class="invalid_info">
                    {{ $store.state.language.inquiry.require_field }}
                </span>
            </div>
            <div class="col-lg-6">
                <label for="fr_main_product">{{
                    $store.state.language.inquiry.main_product
                }}</label>
                <input
                    type="text"
                    class="form-control"
                    id="fr_main_product"
                    v-model="formData.main_product"
                />
            </div>
            <div class="col-lg-6">
                <label for="fr_main_market">{{
                    $store.state.language.inquiry.main_market
                }}</label>
                <select
                    class="form-control"
                    id="fr_main_market"
                    v-model="formData.main_market"
                >
                    <option value="">{{
                        $store.state.language.inquiry.main_market_option_default
                    }}</option>
                    <option
                        v-for="m in $store.state.language.inquiry
                            .main_market_option"
                        :value="m"
                        :key="m"
                        >{{ m }}</option
                    >
                </select>
            </div>
            <div
                class="col-lg-6"
                :class="check_form && formData.website == '' ? 'invalid' : ''"
            >
                <label for="fr_website">
                    <span class="red">*</span>
                    {{ $store.state.language.inquiry.website }}
                </label>
                <input
                    type="text"
                    class="form-control"
                    id="fr_website"
                    v-model="formData.website"
                />
                <span class="invalid_info">
                    {{ $store.state.language.inquiry.require_field }}
                </span>
            </div>

            <div class="col-lg-6">
                <label for="fr_address">{{
                    $store.state.language.inquiry.address
                }}</label>
                <input
                    type="text"
                    class="form-control"
                    id="fr_address"
                    v-model="formData.address"
                />
            </div>
            <div class="col-lg-6">
                <label for="fr_tel">{{
                    $store.state.language.inquiry.tel
                }}</label>
                <input
                    type="text"
                    class="form-control"
                    id="fr_tel"
                    v-model="formData.tel"
                />
            </div>
            <div
                class="col-lg-6"
                :class="check_form && formData.contact == '' ? 'invalid' : ''"
            >
                <label for="fr_contact">
                    <span class="red">*</span>
                    {{ $store.state.language.inquiry.contact }}
                </label>
                <input
                    type="text"
                    class="form-control"
                    id="fr_contact"
                    v-model="formData.contact"
                />
                <span class="invalid_info">
                    {{ $store.state.language.inquiry.require_field }}
                </span>
            </div>
            <div class="col-lg-6">
                <label for="fr_contact_title">{{
                    $store.state.language.inquiry.contact_title
                }}</label>
                <input
                    type="text"
                    class="form-control"
                    id="fr_contact_title"
                    v-model="formData.contact_title"
                />
            </div>
            <div
                class="col-lg-6"
                :class="
                    check_form &&
                    (formData.contact_email == '' || valiad_mail == null)
                        ? 'invalid'
                        : ''
                "
            >
                <label for="fr_contact_email">
                    <span class="red">*</span>
                    {{ $store.state.language.inquiry.contact_email }}
                </label>
                <input
                    type="text"
                    class="form-control"
                    id="fr_contact_email"
                    v-model="formData.contact_email"
                />
                <span class="invalid_info">
                    {{
                        formData.contact_email == ""
                            ? $store.state.language.inquiry.require_field
                            : $store.state.language.inquiry.email_format_invalid
                    }}
                </span>
            </div>
            <div
                class="col-12"
                :class="check_form && !formData.agree ? 'invalid' : ''"
            >
                <input type="checkbox" class="mr-1" v-model="formData.agree" />
                {{ $store.state.language.inquiry.privacy_policy_agree_1 }}
                <a
                    style="color:#00C;cursor:pointer"
                    @click="show_privacy_policy()"
                    >{{ $store.state.language.inquiry.privacy_policy }}</a
                >
                {{ $store.state.language.inquiry.privacy_policy_agree_2 }}
                <br />
                <span class="invalid_info">
                    {{ $store.state.language.inquiry.require_field }}
                </span>
            </div>
            <div class="col-12 text-center mb-5">
                <button
                    class="btn btn-primary btn-lg"
                    v-html="$store.state.language.inquiry.submit"
                    @click="send_inquiry_form()"
                ></button>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
.red {
    color: #f00;
}

.invalid_info,
.valid_info {
    display: none;
}

.inquiry_table {
    th,
    td {
        padding: 8px 10px;
    }

    th {
        background-color: #0060ae;
        color: #ffffff;
    }

    tr:nth-child(even) {
        background: #eeeeee;
    }

    img {
        background: #ffffff;
        border: 1px solid #dddddd;
        box-shadow: 1px 1px 5px 0px #dddddd;
    }
}

.invalid {
    input {
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .invalid_info {
        color: #dc3545;
        display: block;
    }
}
</style>
<script>
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, {
    siteKey: "6LfHnYAeAAAAAG763J4o-g4G3BntOmYdW4--CzYm",
    loaderOptions: {
        useRecaptchaNet: true
    }
});

export default {
    data() {
        return {
            formData: {
                source: "",
                products: [],
                comment: "",
                company: "",
                main_product: "",
                main_market: "",
                website: "",
                address: "",
                tel: "",
                contact: "",
                contact_title: "",
                contact_email: "",
                agree: false
            },
            token: "",
            check_form: false
        };
    },
    mounted() {
        let prod_list = this.get_inquiry();
        this.recaptcha();
        setInterval(this.recaptcha, 110000);
        this.formData.source = this.$store.state.lan;

        prod_list.forEach(pid => {
            let prod = this.$store.state.data.product.find(p => {
                return p.id == pid;
            });
            this.formData.products.push({
                id: prod.id,
                main_img: prod.main_img,
                model: prod.model,
                name: prod.name
            });
        });
    },
    computed: {
        valiad_mail() {
            return String(this.formData.contact_email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        valiad_form() {
            let output = true;
            switch (true) {
                case this.token == "":
                case this.formData.company == "":
                case this.formData.website == "":
                case this.formData.contact == "":
                case this.formData.contact_email == "":
                case !this.formData.agree:
                case this.valiad_mail == null:
                    output = false;
                    break;
            }
            return output;
        }
    },
    methods: {
        async recaptcha() {
            await this.$recaptchaLoaded();
            // Execute reCAPTCHA with action "login".
            this.token = await this.$recaptcha("login");
        },
        get_inquiry() {
            let output = "";
            // Cookie 處理
            const value = `; ${document.cookie}`;
            const parts = value.split(`; inquiry=`);
            const web_inq = parts
                .pop()
                .split(";")
                .shift();
            console.log("Website Inquiry=" + web_inq);
            output = web_inq || "[]";
            return JSON.parse(output);
        },
        del_inquiry(pk) {
            let vue = this,
                inq_data = [];
            //
            vue.formData.products.splice(pk, 1);
            vue.formData.products.forEach(p => {
                inq_data.push(p.id);
            });
            //
            document.cookie = "inquiry=" + JSON.stringify(inq_data);
        },
        show_privacy_policy() {
            this.$swal.fire({
                title: this.$store.state.language.inquiry.privacy_policy,
                html: `<div style='text-align:left'>${this.$store.state.language.inquiry.privacy_policy_content}</div>`,
                width: "90%",
                showCloseButton: true,
                showConfirmButton: false
            });
        },
        send_inquiry_form() {
            let vue = this;
            vue.check_form = true;
            if (vue.valiad_form) {
                const data = new FormData();
                for (let k in vue.formData) {
                    switch (k) {
                        case "products":
                            data.append(k, JSON.stringify(vue.formData[k]));
                            break;
                        default:
                            data.append(k, vue.formData[k]);
                            break;
                    }
                }
                data.append("token", vue.token);
                vue.$swal.fire({
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    html: `
                        <div style="height:5em">
                            <i class="fas fa-circle-notch fa-spin fa-4x" style="color: #42a5f5;"></i>
                        </div>`,
                    showCancelButton: false,
                    showCloseButton: false,
                    showConfirmButton: false,
                    title: "處理中",
                    width: "12rem",
                    didOpen() {
                        vue.axios
                            .post(`/inquiry_save/index.php`, data)
                            .then(res => {
                                if (res.data.status != "ok") {
                                    console.log(res);
                                    vue.$swal.fire({
                                        icon: "error",
                                        title: res.data.msg || "Error!"
                                    });
                                    vue.recaptcha();
                                } else {
                                    // 清空
                                    document.cookie =
                                        "inquiry=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
                                    vue.$swal
                                        .fire({
                                            icon: "success",
                                            title:
                                                vue.$store.state.language
                                                    .inquiry.finish,
                                            showConfirmButton: true,
                                            allowEscapeKey: false,
                                            allowOutsideClick: false
                                        })
                                        .then(() => {
                                            location.reload();
                                        });
                                }
                            });
                    }
                });
            }
        }
    }
};
</script>
